<template>
    <section>
        <modal ref="modalProductos" :titulo="titulo" no-aceptar tamano="modal-lg">
            <template slot="header-slot">
                <div class="col-12 text-center text-general pt-0 pb-2 f-600">
                    {{ fecha }}
                </div>
            </template>
            <div v-for="(producto,p) in productos" :key="p" class="row mx-0 mt-2 px-0 justify-center">
                <div class="col-auto">
                    <img :src="producto.foto_firmada" class="border br-12" style="width:50px;height:50px;object-fit:cover;" alt="" />
                </div>
                <div class="col-8">
                    <p class="mb-0 f-15 f-600 text-general">
                        {{ producto.sku }} - {{ producto.nombre }} - {{ producto.presentacion }}
                    </p>
                    <div class="row">
                        <div class="col-auto f-14 text-general2">
                            {{ params.tipo == 1 ? separadorNumero(producto.valor) : `${agregarSeparadoresNumero(producto.valor)} Unidades` }}
                        </div>
                        <div class="col-auto ml-auto f-18 f-600 text-general2">
                            {{ producto.porcentaje }}%
                        </div>
                    </div>
                    <div class="row" style="height:10px;">
                        <div class="h-100 bg-light-f5 br-5" :style="`width:${producto.porcentaje_max}%`" />
                    </div>
                </div>
            </div>
        </modal>
    </section>
</template>
<script>
import MetricasLeecheroAdmin from "~/services/metricas_leechero_admin"

export default {
    data(){
        return{
            productos: [],
            params: {
                fecha_inicio: null,
                fecha_fin: null,
                tipo: null,
                id_cedis: null,
                id_moneda: null,
            },
            fecha:'',
            titulo: ''
        }
    },
    methods: {
        toggle(params){
            this.$refs.modalProductos.toggle()
            this.params = params
            this.productos = []

            this.fecha = `Durante : ${this.formatearFecha(params.fecha_inicio,'D MMM YYYY')} - ${this.formatearFecha(params.fecha_fin,'D MMM YYYY')}`
            this.titulo = params.tipo == 1 ? 'Productos más comprados en cuanto a dinero' : 'Productos más comprados en cuanto a cantidad'

            this.ver_mas_productos()
        },
        async ver_mas_productos(){
            try {
                const { data } = await MetricasLeecheroAdmin.ver_mas_productos(this.params)
                this.productos = data.data.productos

            } catch (error){

            }
        },
    }
}
</script>
<style lang="scss">
.bg-gradient-general{
    background: var(--gr-r-general);
}
.text-blue-modal{
    color: #0D4DFF;
}
</style>
